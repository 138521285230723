import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tracky from "../components/tracky"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Tracky />
    <div className="main-header">
    <div className="glitch-logo logo-color"></div>
    <h1>error 404</h1>
    </div>
    <div className="main-text">
      <p>The page you're looking for doesn't seem to exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
